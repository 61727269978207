<template>
	<div class="auth-wrapper auth-v2">
		<b-row class="auth-inner m-0">

			<!-- Brand logo-->
			<span class="brand-logo justify-content-center align-items-center">
                <img v-bind:src="logoSrc" height="25"
				/>
                <h2 class="brand-text ml-1">
                    papir.ai
                </h2>
            </span>
			<b-col
				lg="7"
				class="d-none d-lg-flex align-items-center p-5">
				<div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
					<b-img
						fluid
						:src="imgUrl"
						alt="Login V2"/>
				</div>
			</b-col>
			<!-- /Left Text-->

			<!-- Login-->
			<b-col
				lg="5"
				class="d-flex align-items-center auth-bg px-2 p-lg-5"
			>
				<b-col
					sm="8"
					md="6"
					lg="12"
					class="px-xl-2 mx-auto"
				>
					<b-card-title
						class="mb-1 font-weight-bold"
						title-tag="h2"
					>
						{{ $t('welcome') }} 👋
					</b-card-title>
					<b-card-text class="mb-2">
						{{ $t('welcomeMessage') }}
					</b-card-text>

					<!-- form -->
					<validation-observer
						ref="loginForm"
						#default="{invalid}"
					>
						<b-form
							class="auth-login-form mt-2"
							@submit.prevent="login"
						>
							<!-- email -->
							<b-form-group
								:label="$t('username')"
								label-for="login-email"
							>
								<validation-provider
									#default="{ errors }"
									name="Email"
									vid="email"
									rules="required|email"
								>
									<b-form-input
										id="login-email"
										v-model="userEmail"
										:state="errors.length > 0 ? false:null"
										name="login-email"
										placeholder="john@example.com"
									/>
									<small v-if="errors.length > 0" class="text-danger">
										<!--                    {{ errors[0] }}-->
										{{ $t('messages.user.emailRequired') }}
									</small>
								</validation-provider>
							</b-form-group>

							<!-- forgot password -->
							<b-form-group>
								<div class="d-flex justify-content-between">
									<label for="login-password">{{ $t('password') }}</label>
									<b-link :to="{name:'Auth-forgot-password'}">
										<small>{{ $t('forgotPassword') }}</small>
									</b-link>
								</div>
								<validation-provider
									#default="{ errors }"
									name="Password"
									vid="password"
									rules="required"
								>
									<b-input-group
										class="input-group-merge"
										:class="errors.length > 0 ? 'is-invalid' : null"
									>
										<b-form-input
											id="login-password"
											v-model="password"
											:state="password.length >= 5"
											class="form-control-merge"
											:type="passwordFieldType"
											name="login-password"
											:placeholder="$t('password')"
										/>
										<b-input-group-append is-text>
											<feather-icon
												class="cursor-pointer"
												:icon="passwordToggleIcon"
												@click="togglePasswordVisibility"
											/>
										</b-input-group-append>
									</b-input-group>
									<small v-if="errors.length > 0" class="text-danger">
										{{ $t('required') }}
									</small>
								</validation-provider>
							</b-form-group>

							<!-- checkbox -->
							<b-form-group>
								<b-form-checkbox
									id="remember-me"
									v-model="status"
									name="checkbox-1"
								>
									{{ $t('rememberMe') }}
								</b-form-checkbox>
							</b-form-group>

							<!-- submit buttons -->

							<div class="w-100 d-flex justify-content-end">
								<b-button
									type="submit"
									variant="primary"
									class="elevated-btn px-3"
									:disabled="invalid || loading">
									<b-spinner small v-if="loading"/>
									<span v-else>{{ $t('login') }}</span>
								</b-button>
							</div>
						</b-form>
					</validation-observer>

					<b-card-text class="text-center mt-2">
						<span>{{ $t('firstTimeQuestion') }}</span>
						<b-link :to="{name:'Auth-register'}">
							<span>&nbsp;{{ $t('createNewAccount') }}</span>
						</b-link>
					</b-card-text>

					<!-- divider -->
					<!--          <div class="divider my-2">-->
					<!--            <div class="divider-text">-->
					<!--              ya da-->
					<!--            </div>-->
					<!--          </div>-->

					<!-- social buttons -->
					<!--          <div class="auth-footer-btn d-flex justify-content-center">-->
					<!--            <b-button-->
					<!--                disabled-->
					<!--                variant="google"-->
					<!--                href="javascript:void(0)"-->
					<!--            >-->
					<!--              <feather-icon icon="MailIcon"/>-->
					<!--            </b-button>-->
					<!--            <b-button-->
					<!--                disabled-->
					<!--                variant="github"-->
					<!--                href="javascript:void(0)"-->
					<!--            >-->
					<!--              <feather-icon icon="GithubIcon"/>-->
					<!--            </b-button>-->
					<!--          </div>-->
				</b-col>
			</b-col>
			<!-- /Login-->
		</b-row>
	</div>
</template>

<script>
/* eslint-disable global-require */
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {
	BButton,
	BCardText,
	BCardTitle,
	BCol,
	BForm,
	BFormCheckbox,
	BFormGroup,
	BFormInput,
	BImg,
	BInputGroup,
	BInputGroupAppend,
	BLink,
	BRow,
	BSpinner,
	VBTooltip,
} from 'bootstrap-vue'

import {email, required} from '@validations'
import {togglePasswordVisibility} from '@core/mixins/ui/forms'
import store from '@/store/index'
//import {getHomeRouteForLoggedInUser} from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {AuthenticationError} from "@/services/UserAccountService";
import i18n from "@/libs/i18n";
import {locales} from "@/libs/locales";

export default {
	directives: {
		'b-tooltip': VBTooltip,
	},
	components: {
		BRow,
		BCol,
		BLink,
		BFormGroup,
		BFormInput,
		BSpinner,
		BInputGroupAppend,
		BInputGroup,
		BFormCheckbox,
		BCardText,
		BCardTitle,
		BImg,
		BForm,
		BButton,
		ValidationProvider,
		ValidationObserver,
	},
	mixins: [togglePasswordVisibility],
	data() {
		return {
			logoSrc: require('@/assets/images/logo/papirai-logo.svg'),
			status: '',
			password: '',
			userEmail: null,
			sideImg: require('@/assets/images/pages/login-v2.svg'),
			loading: false,
			// validation rules
			required,
			email,
		}
	},
	computed: {
		passwordToggleIcon() {
			return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
		},
		imgUrl() {
			if (store.state.appConfig.layout.skin === 'dark') {
				// eslint-disable-next-line vue/no-side-effects-in-computed-properties
				this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
				return this.sideImg
			}
			return this.sideImg
		},
	},
	methods: {
		login() {
			this.$refs.loginForm.validate().then(success => {
				if (success) {
					this.loading = true
					this.$store.dispatch('auth/login', {
						username: this.userEmail,
						password: this.password,
					}).then(response => {
						this.loading = false
						// ? This is just for demo purpose as well.
						// ? Because we are showing eCommerce app's cart items count in navbar
						// this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', userData.extras.eCommerceCartItemsCount)
						// ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
						// Redirect to login page

						locales.forEach(l => {
							if (l.value === response.data.preferredLanguage) {
								localStorage.setItem('language', l.locale)
								this.$i18n.locale = l.locale
							}
						})

						const hasAtLeastOneWorkspace = response.data.hasAtLeastOneWorkspace
						if (hasAtLeastOneWorkspace === null || !hasAtLeastOneWorkspace) {
							this.$router.replace({name: 'first-workspace'})
								.catch((e) => {
									console.log(e)
								})
						} else {
							this.$router.replace('/')
								.catch((e) => {
									console.log(e)
								})
						}
					}).catch((err) => {
						this.loading = false
						if (err instanceof AuthenticationError) {
							this.$toast({
								component: ToastificationContent,
								props: {
									title: i18n.t('messages.user.failedOnLogin'),
									text: i18n.t('messages.user.wrongEmailOrPassword'),
									icon: 'AlertTriangeIcon',
									variant: 'danger',
								},
							}, {position: 'top-center'})
						}
					})
				}
			})
		},
	},
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
